import AddBookFromGoodreadsModal from "./AddBookFromGoodreadsModal";
import BookSocialActivityModal from "./BookSocialActivityModal";
import ConfirmationModal from "./ConfirmationModal";
import LibraryImportDirectionsModal from "./LibraryImportDirectionsModal";
import LoginPromptModal from "./LoginPromptModal";
import NewReviewModal from "./NewReviewModal";
import SearchReturnBookModal from "./SearchReturnBookModal";
import SearchTBRModal from "./SearchTBRModal";
import ShowPostModal from "./ShowPostModal";
import ShowReviewModal from "./ShowReviewModal";
import ViewMembersModal from "./ViewMembersModal";
import InfoModal from "./InfoModal";
import NewPostModal from "./NewPostModal";
import ShowActivityItemModal from "./ShowActivityItemModal";
import ProfileFollowsModal from "./ProfileFollowsModal";
import ProfilePointsModal from "./ProfilePointsModal";
import BadgeModal from "./BadgeModal";
import QuestSocialActivityModal from "./QuestSocialActivityModal";

const MODAL_CONFIG = {
  addBookFromGoodreads: AddBookFromGoodreadsModal,
  bookSocialActivity: BookSocialActivityModal,
  confirmation: ConfirmationModal,
  libraryImportDirections: LibraryImportDirectionsModal,
  loginPrompt: LoginPromptModal,
  newReview: NewReviewModal,
  searchReturnBook: SearchReturnBookModal,
  searchTBR: SearchTBRModal,
  showPost: ShowPostModal,
  showReview: ShowReviewModal,
  viewMembers: ViewMembersModal,
  info: InfoModal,
  newPost: NewPostModal,
  showActivityItem: ShowActivityItemModal,
  profileFollows: ProfileFollowsModal,
  profilePoints: ProfilePointsModal,
  badgeInfo: BadgeModal,
  questSocialActivity: QuestSocialActivityModal,
};

export default MODAL_CONFIG;
