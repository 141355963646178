import React, { useState, useEffect } from "react";
import { openModal } from "#redux/slices/globalUISlice";
import { useDispatch, useSelector } from "react-redux";
import Collapsible from "react-collapsible";
import clsx from "clsx";
import { fetchShelves } from "#redux/slices/shelfSlice";
import check from "#assets/graphic-check.svg";
import { updateUserBook, createUserBook } from "#redux/slices/userBookSlice";
import Modal from "../modals/Modal";
import { BOOK_STATUS_BG_COLOR, BOOK_STATUS_ICON_MAP, BOOK_STATUS_TEXT_MAP } from "#utils/constants";
import Button from "#helpers/Button";
import { getMaterialIcon } from "#utils/icons";
import ReadingInstances from "#helpers/ReadingInstances";
const LockOutlinedIcon = getMaterialIcon("LockOutlined");

const BookStatusButton = ({ userBook, bookId, variant, setUserBookData }) => {
  const dispatch = useDispatch();
  const status = userBook?.status || "set-status";
  const { currentUserId } = useSelector((state) => state.user);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShelves, setSelectedShelves] = useState(userBook ? userBook.shelves.map((shelf) => shelf.id) : []);
  const [selectedStatus, setSelectedStatus] = useState(userBook?.status || "none");
  const [readingInstances, setReadingInstances] = useState(userBook?.reading_instances || []);
  const { shelves, loading } = useSelector((state) => state.shelf);

  useEffect(() => {
    setSelectedStatus(userBook?.status || "none");
    setSelectedShelves(userBook?.shelves.map((shelf) => shelf.id) || []);
    setReadingInstances(userBook?.reading_instances || []);
  }, [userBook]);

  const handleOpenModal = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!currentUserId) return dispatch(openModal("loginPrompt"));
    if (shelves.length === 0) dispatch(fetchShelves());
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setReadingInstances(userBook?.reading_instances || []);
    setSelectedShelves(userBook?.shelves.map((shelf) => shelf.id) || []);
    setSelectedStatus(userBook?.status || "none");
    setIsModalOpen(false);
  };

  const renderModal = () => {
    const handleSave = () => {
      if (userBook && userBook.id) {
        let data = {};
        data.status = selectedStatus;
        data.reading_instances = readingInstances;
        data.shelf_ids = selectedShelves;

        dispatch(updateUserBook({ userBookUuid: userBook.uuid, data }))
          .unwrap()
          .then((payload) => setUserBookData(payload));
        setIsModalOpen(false);
      } else if (selectedStatus !== "none" || selectedShelves.length > 0) {
        dispatch(
          createUserBook({
            userBookData: { status: selectedStatus, book_id: bookId },
            shelfIds: selectedShelves,
          })
        )
          .unwrap()
          .then((payload) => setUserBookData(payload));
      }
      setIsModalOpen(false);
    };

    const handleToggle = (shelfId) => {
      if (selectedShelves.includes(shelfId)) {
        const updatedShelves = selectedShelves.filter((selectedShelf) => selectedShelf !== shelfId);
        setSelectedShelves(updatedShelves);
      } else {
        const updatedShelves = [...selectedShelves, shelfId];
        setSelectedShelves(updatedShelves);
      }
    };

    const renderButton = (status) => {
      const isSelected = selectedStatus === status;
      return (
        <div className={clsx("rounded-boxy", isSelected && "shadow-highlight")} key={status}>
          <button
            className={clsx(
              BOOK_STATUS_BG_COLOR[status],
              "flex flex-row gap-1 items-center justify-center shadow-main font-bold font-space text-[11px] md:text-[13px] rounded-boxy w-[115px] py-1 md:py-1.5 cursor-pointer border border-offBlack"
            )}
            onClick={() => setSelectedStatus(status)}
            type="button"
          >
            {status === "none" ? "none" : BOOK_STATUS_TEXT_MAP[status]}

            <img
              src={BOOK_STATUS_ICON_MAP[status]}
              alt={status}
              className="w-[13px] h-[13px] md:w-5 md:h-5 object-contain"
            />
          </button>
        </div>
      );
    };

    const chevron = "relative after:transform after:transition-all after:font-sans after:content-['›'] after:absolute";
    return (
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={<h4>Update book in library</h4>}
        classNames={{ bg: BOOK_STATUS_BG_COLOR[selectedStatus] }}
      >
        <div className="w-[300px]">
          <hr className="text-gray my-3" />
          <span>Set reading status</span>
          <div className="flex flex-col gap-2 items-center mt-3">
            {["tbr", "current", "finished", "dnf", "paused", "none"].map((status) => renderButton(status))}
          </div>

          {userBook && (
            <React.Fragment>
              <hr className="text-gray my-4" />
              <div className="flex flex-col gap-1 my-2 md:gap-2 md:my-4">
                <Collapsible
                  trigger={<span className="mr-3">Add or edit dates read</span>}
                  triggerClassName={`${chevron} after:rotate-90`}
                  triggerOpenedClassName={`${chevron} after:-rotate-90`}
                  transitionTime={200}
                >
                  <div
                    className={`flex w-full ${userBook?.status != "current" && userBook?.status != "paused" && "mt-2"}`}
                  >
                    <ReadingInstances
                      readingInstances={readingInstances}
                      setReadingInstances={setReadingInstances}
                      skipDelete={
                        readingInstances?.length === 1 &&
                        (userBook?.status === "finished" || userBook?.status === "dnf")
                      }
                    />
                  </div>
                </Collapsible>
                {userBook?.status != selectedStatus && (
                  <span className="text-xs">Save your status change to see updated dates</span>
                )}
              </div>
            </React.Fragment>
          )}

          <hr className="text-gray my-4" />
          <div className="flex flex-col gap-1 my-2 md:gap-2 md:my-4">
            <Collapsible
              trigger={<span className="mr-3">Add tags</span>}
              triggerClassName={`${chevron} after:rotate-90`}
              triggerOpenedClassName={`${chevron} after:-rotate-90`}
              transitionTime={200}
            >
              <p className="text-xs mb-4">Select as many as you'd like</p>
              <div className="flex flex-col w-full gap-1">
                {loading ? (
                  <div>Loading...</div>
                ) : shelves.length === 0 ? (
                  <div className="text-sm">No tags yet!</div>
                ) : (
                  shelves.map(({ id, name, private: isPrivate }) => {
                    const isSelected = selectedShelves.includes(id);
                    return (
                      <label
                        key={id}
                        htmlFor={id}
                        className={clsx(
                          "py-1 px-2 hover:bg-mainGreen flex justify-between items-center rounded-default cursor-pointer",
                          isSelected && "bg-mainGreen"
                        )}
                      >
                        <div className="flex flex-row gap-1 items-center">
                          {isSelected && <img src={check} alt="selected" className="w-5 h-5" />}
                          {name}
                        </div>
                        {isPrivate && <LockOutlinedIcon className="text-offBlack" fontSize="small" />}
                        <input
                          hidden
                          id={id}
                          type="checkbox"
                          checked={isSelected}
                          value={name}
                          onChange={() => handleToggle(id)}
                        />
                      </label>
                    );
                  })
                )}
              </div>
            </Collapsible>
          </div>
          <div className="flex flex-row justify-end mt-4">
            <Button text="Save" onClick={handleSave} />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {variant === "button" ? (
        <button
          className={clsx(
            BOOK_STATUS_BG_COLOR[status],
            "flex flex-row gap-1 items-center justify-center shadow-main font-bold font-space text-[11px] md:text-[13px] rounded-boxy w-[87px] md:w-[106px] py-1 md:py-1.5 cursor-pointer border border-offBlack"
          )}
          onClick={handleOpenModal}
          key={status}
          type="button"
        >
          {BOOK_STATUS_TEXT_MAP[status]}
          <img
            src={BOOK_STATUS_ICON_MAP[status]}
            alt={status}
            className="w-[13px] h-[13px] md:w-5 md:h-5 object-contain"
          />
        </button>
      ) : (
        <button onClick={handleOpenModal}>
          <img src={BOOK_STATUS_ICON_MAP[status]} alt="selected" className="w-5 h-5 cursor-pointer object-contain" />
        </button>
      )}
      {isModalOpen && renderModal()}
    </>
  );
};

export default BookStatusButton;
