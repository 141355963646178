import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { navigate } from "vike/client/router";
import { TableRow, TableCell, Rating } from "@mui/material";
import { openModal } from "#redux/slices/globalUISlice";
import BookCover from "#helpers/BookCover";
import BookStatusButton from "#helpers/BookStatusButton";
import { formatDate } from "#utils/utils";
import Button from "#helpers/Button";
import { BOOK_STATUS_BG_COLOR } from "#utils/constants";
import { getMaterialIcon } from "#utils/icons";
const StarRoundedIcon = getMaterialIcon("StarRounded");

const BookCard = ({ book, icon, variant, showAddedAt = true }) => {
  const dispatch = useDispatch();
  const [userBookData, setUserBookData] = useState(book?.user_book);

  useEffect(() => {
    setUserBookData(book?.user_book);
  }, [book]);

  if (variant === "large") {
    return (
      <div
        onClick={() => navigate(`/books/${book.uuid}`)}
        className="no-hover relative flex w-full cursor-pointer max-w-[900px] p-4 gap-4 md:p-[20px] md:gap-6 text-left bg-white border border-offBlack shadow-main rounded-default"
      >
        <div className="flex flex-none">
          <BookCover book={book} size="w-[62px] h-24 md:w-[106px] md:h-[165px]" clickable={false} />
        </div>

        <div className="flex flex-col w-full gap-3 text-left justify-between md:pl-2">
          <div className="flex flex-col gap-1 w-full">
            <p className="font-bold leading-none overflow-wrap">{book.title}</p>
            <p className="leading-tight text-[11px] md:text-[14px] truncate">{book.author_name}</p>
            <div className="hidden md:block">
              <div className="leading-tight text-[12px] text-ellipsis line-clamp-4 overflow-hidden mt-3">
                {book.description}
              </div>
            </div>
          </div>
          <BookStatusButton
            userBook={userBookData}
            bookId={book.id}
            setUserBookData={setUserBookData}
            variant="button"
          />
        </div>
      </div>
    );
  }

  if (variant === "vertical") {
    return (
      <div
        className={`relative flex flex-col flex-none box-border p-2 md:p-4 ${
          BOOK_STATUS_BG_COLOR[userBookData?.status || "set-status"]
        } items-center rounded-boxy border border-offBlack`}
      >
        <div
          className={`absolute flex justify-center items-center -top-[1px] md:-top-[1px] -right-[8px] md:-right-[6px] w-[33px] h-[30px] md:w-[36px] md:h-[33px] border border-offBlack rounded-default shadow-main ${
            BOOK_STATUS_BG_COLOR[userBookData?.status || "set-status"]
          }`}
        >
          {icon || <BookStatusButton userBook={userBookData} bookId={book.id} setUserBookData={setUserBookData} />}
        </div>
        <div className="w-[68px] h-[104px] md:w-[104px] md:h-40 cursor-pointer">
          <BookCover book={book} size="w-[68px] h-[104px] md:w-[104px] md:h-40" showTitle />
        </div>
      </div>
    );
  }

  if (variant === "homeFeed") {
    const canWriteReview = userBookData?.status === "dnf" || userBookData?.status === "finished";
    return (
      <div
        className={`relative flex flex-col box-border p-2 md:p-4 gap-4 ${
          BOOK_STATUS_BG_COLOR[userBookData?.status || "set-status"]
        } items-center rounded-boxy border border-offBlack`}
      >
        <div
          className={`absolute flex justify-center items-center -top-[1px] md:-top-[1px] -right-[8px] md:-right-[6px] w-[33px] h-[30px] md:w-[36px] md:h-[33px] border border-offBlack rounded-default shadow-main ${
            BOOK_STATUS_BG_COLOR[userBookData?.status || "set-status"]
          }`}
        >
          <BookStatusButton userBook={userBookData} bookId={book.id} setUserBookData={setUserBookData} />
        </div>
        <div className="w-[62px] h-24 md:w-[104px] md:h-40 cursor-pointer">
          <BookCover book={book} showTitle size="w-[62px] h-24 md:w-[104px] md:h-40" />
        </div>

        <div className="flex items-center justify-center flex-col gap-2">
          <Button
            size="medium"
            text="Post an update"
            color="bg-neonBlue hidden md:block"
            onClick={() => dispatch(openModal({ name: "newPost", data: { forum: book.forum, isCurrentRead: true } }))}
          />
          <Button
            size="small"
            text="Post update"
            color="bg-neonBlue md:hidden"
            onClick={() => dispatch(openModal({ name: "newPost", data: { forum: book.forum, isCurrentRead: true } }))}
          />

          <Button
            size="medium"
            text={userBookData?.review ? "See review" : "Leave a review"}
            color={`bg-fuschia ${canWriteReview ? "hidden md:block" : "hidden"}`}
            onClick={() =>
              dispatch(
                openModal({
                  name: "newReview",
                  data: {
                    userBook: userBookData,
                    review: userBookData?.review,
                    handleReviewSubmit: (data) => {
                      setUserBookData({ ...userBookData, ...{ review: data.newReview } });
                    },
                  },
                })
              )
            }
          />
          <Button
            size="small"
            text={userBookData?.review ? "See review" : "Leave a review"}
            color={`bg-fuschia ${canWriteReview ? "md:hidden" : "hidden"}`}
            onClick={() =>
              dispatch(
                openModal({
                  name: "newReview",
                  data: {
                    userBook: userBookData,
                    review: userBookData?.review,
                    handleReviewSubmit: (data) => {
                      setUserBookData({ ...userBookData, ...{ review: data.newReview } });
                    },
                  },
                })
              )
            }
          />
        </div>
      </div>
    );
  }

  if (variant === "row") {
    return (
      <TableRow key={book.uuid}>
        <TableCell>
          <BookCover book={book} clickable size="w-[65px] h-[100px]" />
        </TableCell>
        <TableCell>
          <a href={`/books/${book.uuid}`} className="line-clamp-2">
            {book.title}
          </a>
        </TableCell>
        <TableCell>
          <a href={`/authors/${book.author_uuid}`}>{book.author_name}</a>
        </TableCell>
        <TableCell>
          <div className="line-clamp-3 text-sm">{book.description}</div>
        </TableCell>
        <TableCell>
          {book.aggregate_ratings ? (
            <Rating
              value={Number(book.aggregate_ratings ?? 0)}
              precision={0.1}
              size="small"
              readOnly
              icon={<StarRoundedIcon className="stroke-offBlack text-yellow" fontSize="small" />}
              emptyIcon={<StarRoundedIcon className="stroke-offBlack text-white" fontSize="small" />}
            />
          ) : (
            "None yet!"
          )}
        </TableCell>
        {showAddedAt && <TableCell align="center">{formatDate(book.added_at)}</TableCell>}
        <TableCell>
          <div className="flex flex-col gap-2">
            {icon || <BookStatusButton userBook={userBookData} setUserBookData={setUserBookData} bookId={book.id} />}
          </div>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div
      className={`relative flex md:flex-col w-full max-w-[330px] h-[130px] md:w-fit md:h-fit gap-4 md:gap-0 box-border p-4 ${
        BOOK_STATUS_BG_COLOR[userBookData?.status || "set-status"]
      } items-start md:items-center md:shadow-none shadow-main rounded-default md:rounded-boxy border border-offBlack`}
    >
      <div className="absolute md:hidden top-2 right-2">{icon}</div>
      <div
        className={`hidden absolute md:flex justify-center items-center -top-[1px] md:-top-[1px] -right-[8px] md:-right-[6px] w-[33px] h-[30px] md:w-[36px] md:h-[33px] border border-offBlack rounded-default shadow-main ${
          BOOK_STATUS_BG_COLOR[userBookData?.status || "set-status"]
        }`}
      >
        {icon || <BookStatusButton userBook={userBookData} bookId={book.id} setUserBookData={setUserBookData} />}
      </div>
      <div className="md:hidden w-[62px] h-24 cursor-pointer flex-none">
        <BookCover book={book} size="w-[62px] h-24" showTitle clickable={false} />
      </div>
      <div className="hidden md:flex w-[104px] h-40 cursor-pointer flex-none">
        <BookCover book={book} size="w-[104px] h-40" showTitle />
      </div>
      <div className="flex md:hidden flex-col items-start justify-between h-full max-w-full pr-4">
        <a href={`/books/${book.uuid}`} className="no-hover flex flex-col gap-1 text-left">
          <p className="font-bold leading-tight text-[14px] text-ellipsis line-clamp-3 w-full">{book.title}</p>
          <p className="leading-tight text-[12px] w-full md:text-center">{book.author_name}</p>
        </a>
        <div className="justify-self-end">
          <BookStatusButton
            userBook={userBookData}
            bookId={book.id}
            setUserBookData={setUserBookData}
            variant="button"
          />
        </div>
      </div>
    </div>
  );
};

export default BookCard;
