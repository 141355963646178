import React, { useState, useEffect } from "react";
import { navigate } from "vike/client/router";
import { useDispatch } from "react-redux";
import { timeAgo } from "#utils/utils";
import Votes from "#helpers/Votes";
import PostActions from "#components/forum/showPost/PostActions";
import { downvotePost, updatePost, upvotePost } from "#redux/slices/forumSlice";
import { SpoilerTag } from "#helpers/Tag";
import ChatBubble from "#assets/chat-bubble.svg";
import pin from "#assets/pin.svg";
import Button from "#helpers/Button";
import UserProvidedContent from "#helpers/richText/UserProvidedContent";
import { openModal, setModalDirty } from "#redux/slices/globalUISlice";
import BookCover from "#components/helpers/BookCover";
import Badge from "#components/helpers/Badge";

const PostCard = ({ postData, variant, openInModal = true, commentId }) => {
  const dispatch = useDispatch();
  const [post, setPost] = useState(postData);
  const [isEditing, setIsEditing] = useState(false);
  const [newContent, setNewContent] = useState(post.content);
  if (!post) return;

  useEffect(() => {
    setPost(postData);
  }, [postData]);

  const handleUpvote = (event) => {
    event.stopPropagation();
    dispatch(upvotePost(post.id)).then((data) => {
      setPost(data.payload);
    });
  };

  const handleDownvote = (event) => {
    event.stopPropagation();
    dispatch(downvotePost(post.id)).then((data) => {
      setPost(data.payload);
    });
  };

  const onChangeContent = (event) => {
    dispatch(setModalDirty(true));
    setNewContent(event.target.value);
  };

  const handleSubmitEdit = () => {
    const postData = { is_edited: true, content: newContent };
    dispatch(updatePost({ postId: post.id, postData }))
      .unwrap()
      .then((data) => {
        setPost(data);
        setIsEditing(false);
        dispatch(setModalDirty(false));
      });
  };

  const onClickPostCard = () => {
    if (isEditing) return;

    if (openInModal) {
      dispatch(
        openModal({ name: "showPost", data: { postId: post.id, postData: post, setCardPost: setPost, commentId } })
      );
    } else {
      navigate(`/posts/${post.id}${commentId ? `?commentId=${commentId}` : ""}`);
    }
  };

  return (
    <li
      key={post.uuid}
      onClick={onClickPostCard}
      className="flex hover:cursor-pointer bg-white rounded-default p-4 md:p-5 border border-offBlack shadow-main gap-4 md:gap-6 max-h-[250px] text-left"
    >
      {post.book && variant === "withImage" && (
        <div className="flex flex-none w-[54px] h-[80px] md:w-[60px] md:h-[99px]" onClick={(e) => e.stopPropagation()}>
          <BookCover book={post.book} size="w-[54px] h-[80px] md:w-[60px] md:h-[99px]" />
        </div>
      )}
      {post.badge && variant === "withImage" && (
        <>
          <div className="hidden md:block" onClick={(e) => e.stopPropagation()}>
            <Badge
              badge={{ image_url: post.badge.image_url, level: post.forum_type == "Side Quest" ? "champion" : "gold" }}
              size="large"
              onClick={() => {
                navigate(post.forum_link);
              }}
            />
          </div>
          <div className="block md:hidden" onClick={(e) => e.stopPropagation()}>
            <Badge
              badge={{ image_url: post.badge.image_url, level: post.forum_type == "Side Quest" ? "champion" : "gold" }}
              size="medium"
              onClick={() => {
                navigate(post.forum_link);
              }}
            />
          </div>
        </>
      )}
      <div className="flex flex-col gap-2 md:gap-3 flex-1">
        <div className="flex w-full text-xs text-gray justify-between items-center">
          <div className="flex gap-6 items-center">
            <a href={`/users/${post.username}`} onClick={(e) => e.stopPropagation()}>
              {post.username}
            </a>
            {/* <div>{timeAgo(post.created_at)}</div> */}
            {post.is_edited && <div>Edited</div>}
          </div>
          <div className="flex gap-3 items-center">
            {post.is_pinned && <img src={pin} alt="pin" className="w-4 h-4" />}
            {isEditing ? (
              <Button onClick={handleSubmitEdit} text="Update" color="bg-neonBlue text-offBlack" />
            ) : (
              <PostActions post={post} setPost={setPost} onEdit={() => setIsEditing(true)} />
            )}
          </div>
        </div>
        <div className="flex gap-2.5 justify-between md:justify-normal">
          <b>{post.is_flagged || post.deleted_at ? "[deleted]" : post.title}</b>
          {post.is_spoiler && <SpoilerTag />}
        </div>

        <div className="text-[14px] md:text-[16px] text-ellipsis line-clamp-4">
          {isEditing ? (
            <textarea value={newContent} onChange={onChangeContent} rows={3} className="w-full py-3 px-4 my-auto" />
          ) : (
            <UserProvidedContent resource={post} content={post.content} type="post" />
          )}
        </div>
        <div className="flex gap-3 items-center font-bold">
          <Votes
            handleUpvote={handleUpvote}
            handleDownvote={handleDownvote}
            userVote={post.user_vote}
            upvotes={post.upvotes}
          />
          <div
            style={{
              boxShadow: `1.8px 1.8px 0 0 #9C9C9C`,
            }}
            className="items-center text-xs text-gray rounded-default border py-1 px-3 bg-white border-gray"
          >
            <img src={ChatBubble} alt="comments" className="inline" />
            {"  "}
            {post.comment_count}
          </div>
          <div className="text-gray text-xs">Reply</div>
        </div>
      </div>
    </li>
  );
};

export default PostCard;
