import { useDispatch, useSelector } from "react-redux";
import { navigate } from "vike/client/router";
import Modal from "./Modal";
import FullPost from "../forum/showPost/FullPost";
import expand from "#assets/graphic-expand.svg";
import { closeModal } from "#redux/slices/globalUISlice";
import BookCover from "#components/helpers/BookCover";
import Badge from "#components/helpers/Badge";

const ShowPostModal = ({ postId, postData, setCardPost, commentId }) => {
  const dispatch = useDispatch();
  const post = postData || useSelector((state) => state.forum.posts.find((p) => p.id === postId));
  if (!post) return;

  const navigateToForum = () => {
    navigate(post.forum_link);
    dispatch(closeModal());
  };

  const bookSquare = () => {
    const altBookImg = "/assets/AltBookNoBorder.svg";
    return (
      <div
        onClick={navigateToForum}
        className="w-[40px] h-[40px] shadow-main cursor-pointer border"
        style={{
          backgroundImage: `url(${post.book.image_url || altBookImg})`,
          backgroundSize: "cover",
          backgroundPosition: "start",
        }}
      />
    );
  };

  return (
    <Modal
      isOpen
      title={
        <div className="flex items-center gap-2">
          {post.book && bookSquare()}
          {post.badge && (
            <Badge
              badge={{ image_url: post.badge.image_url, level: post.forum_type == "Side Quest" ? "champion" : "gold" }}
              onClick={navigateToForum}
              size="small"
            />
          )}
          <span onClick={navigateToForum} className="text-[#004ABE] font-bold text-sm cursor-pointer hover:text-pink">
            {post.forum_type.includes("Quest") ? "Quest" : post.forum_type} forum
          </span>
        </div>
      }
      classNames={{
        container: "min-w-[95%] md:min-w-[90%] lg:min-w-[700px] lg:max-w-[800px] !overflow-y-clipped",
        margin: "mt-[30%] md:mt-[120px]",
      }}
      headerActions={[
        {
          icon: expand,
          onClick: () => {
            navigate(`/posts/${postId}`);
            dispatch(closeModal());
          },
        },
      ]}
    >
      <FullPost postData={post} setCardPost={setCardPost} commentId={commentId} />
    </Modal>
  );
};

export default ShowPostModal;
