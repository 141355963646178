import React, { useState } from "react";
import Button from "#helpers/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ReadingInstances = ({ readingInstances, setReadingInstances, skipDelete }) => {
  const [error, setError] = useState(null);
  const onDelete = (index) => {
    setReadingInstances((instances) => instances.filter((_, i) => i !== index));
  };

  const onAdd = () => {
    setReadingInstances([
      ...readingInstances,
      {
        id: null,
        started_reading_at: null,
        finished_reading_at: null,
      },
    ]);
  };

  const onUpdate = (index, field, value) => {
    const newInstances = [...readingInstances];
    newInstances[index] = {
      ...newInstances[index],
      [field]: value,
    };
    setReadingInstances(newInstances);
  };

  const formatDate = (date) => {
    if (!date) return null;
    if (typeof date === "string") {
      // For YYYY-MM-DD string from server, create date that will display as UTC
      const [year, month, day] = date.split("-").map(Number);
      const localDate = new Date(year, month - 1, day);
      const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
      return utcDate;
    }
    return date;
  };

  const renderReadingInstance = (instance, index) => {
    return (
      <div className="flex flex-col w-full p-[2px]" key={index}>
        {instance.current && <div className="text-xs mb-[1px]">Current</div>}
        <div className="flex flex-row w-full gap-1 items-center">
          <div className="flex flex-col w-full gap-[1px]">
            <div className="flex flex-row gap-1 px-2 items-center bg-white bg-opacity-80 rounded-tl-boxy rounded-tr-boxy">
              <label className="lg:text-sm min-w-[45px]" htmlFor="startDate">
                Started:
              </label>
              <DatePicker
                selected={formatDate(instance.started_reading_at)}
                maxDate={new Date().toISOString().split("T")[0]}
                utcOffset={0}
                placeholderText="No date"
                onChange={(date) => {
                  const dateString = date?.toISOString()?.split("T")[0];
                  if (instance.finished_reading_at && dateString > instance.finished_reading_at) {
                    setError("Start date cannot be after finish date");
                    return;
                  } else {
                    setError(null);
                    onUpdate(index, "started_reading_at", dateString);
                  }
                }}
                className="lg:text-sm bg-transparent border-none focus:ring-0 p-1"
              />
            </div>

            <div className="flex flex-row gap-1 px-2 items-center bg-white bg-opacity-80 rounded-bl-boxy rounded-br-boxy">
              <label className="lg:text-sm min-w-[45px]" htmlFor="finishDate">
                Finished:
              </label>

              {instance.current && !instance.finished_reading_at ? (
                <div className="lg:text-sm p-1 rounded-sm flex-grow appearance-none bg-transparent border-none self-center lg:self-start">
                  Not finished yet
                </div>
              ) : (
                <React.Fragment>
                  <DatePicker
                    selected={formatDate(instance.finished_reading_at)}
                    maxDate={new Date().toISOString().split("T")[0]}
                    placeholderText="No date"
                    utcOffset={0}
                    onChange={(date) => {
                      const dateString = date?.toISOString()?.split("T")[0];
                      if (instance.started_reading_at && dateString < instance.started_reading_at) {
                        setError("Finish date cannot be before start date");
                        return;
                      } else {
                        setError(null);
                        onUpdate(index, "finished_reading_at", dateString);
                      }
                    }}
                    className="lg:text-sm bg-transparent border-none focus:ring-0 p-1"
                  />
                </React.Fragment>
              )}
            </div>
          </div>

          <button
            disabled={instance.current || skipDelete}
            onClick={() => onDelete(index)}
            className={`p-1 ${(instance.current || skipDelete) && "opacity-0"}`}
            type="button"
          >
            ×
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full gap-2 p-[2px]">
      {readingInstances.map((instance, index) => renderReadingInstance(instance, index))}
      {error && <div className="text-sm">‼️ {error}</div>}
      <Button onClick={onAdd} text="+ Add" color="bg-fuschia" />
    </div>
  );
};

export default ReadingInstances;
