import { useSuspenseQuery } from "@tanstack/react-query";
import api from "#utils/api";
import UserCard from "#cards/UserCard";
import Modal from "./Modal";

const ProfileFollowsModal = ({ isFollowers }) => {
  const { data: follows } = useSuspenseQuery({
    queryKey: ["profile", "follows"],
    queryFn: () => api.get(`/users/current/follows?is_followers=${isFollowers}`),
  });

  return (
    <Modal isOpen title={<h4>{isFollowers ? "Followers" : "Following"}</h4>}>
      <div className="flex flex-col gap-3 mt-4 md:min-w-[500px]">
        {follows.map((u) => (
          <UserCard key={u.id} user={u} />
        ))}
      </div>
      {follows.length === 0 && (
        <div className="mt-6 text-center">
          <b>{isFollowers ? "You don't have any followers yet!" : "You're not following anyone yet!"}</b>
          <p className="mt-3">
            {isFollowers ? (
              "Invite a friend to Pagebound to follow along with their books and posts."
            ) : (
              <>
                Browse people already on Pagebound{" "}
                <a href="/discover/people" className="text-pink">
                  here
                </a>{" "}
                or invite a friend.
              </>
            )}
          </p>
        </div>
      )}
    </Modal>
  );
};

export default ProfileFollowsModal;
