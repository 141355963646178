import React from "react";
import Badge from "#components/helpers/Badge";

const QuestCard = ({ quest }) => {
  const joinButton = () => {
    if (quest.joined) {
      return (
        <div className="flex flex-none bg-yellow h-fit rounded-default border-2 border-orange px-4 py-1 ml-2">
          <p className="leading-tight text-[11px] md:text-[13px]">
            {quest.user_challenge?.completed_books_count || 0} read
          </p>
        </div>
      );
    }

    return (
      <div className="flex flex-none bg-beige h-fit rounded-default border border-offBlack px-4 py-1">
        <p className="leading-tight text-[11px] md:text-[13px]">Not Joined</p>
      </div>
    );
  };

  const badges = () => {
    if (!quest?.badge?.image_url) return <></>;
    if (quest.challenge_type == "Side Quest") {
      return <Badge badge={{ image_url: quest.badge.image_url, level: "champion" }} size="medium" />;
    } else {
      return (
        <div className="flex flex-row -space-x-2">
          <Badge badge={{ image_url: quest.badge.image_url, level: "bronze" }} size="medium" />
          <Badge badge={{ image_url: quest.badge.image_url, level: "silver" }} size="medium" />
          <Badge badge={{ image_url: quest.badge.image_url, level: "gold" }} size="medium" />
        </div>
      );
    }
  };

  return (
    <a
      href={`/quests/${quest.uuid}`}
      className="no-hover relative w-full max-w-[800px] h-[126px] md:h-[138px] flex flex-col p-3 md:p-4 gap-3 bg-white border border-offBlack shadow-main rounded-default cursor-pointer items-center justify-center"
    >
      <div className="flex flex-row text-left w-full justify-between">
        <div className="flex flex-col gap-2">
          <p className="font-bold text-sm md:text-base leading-tight line-clamp-2 md:line-clamp-1">
            {quest.name} {quest.emojis && quest.emojis.map((emoji) => emoji)}
          </p>
          <p className="leading-none text-xs md:text-sm">
            {quest.challenge_type == "Main Quest" ? "🏆" : "💎"} // {quest.participant_count} joined
          </p>
        </div>

        {joinButton()}
      </div>

      <div className="flex flex-row gap-3 items-center justify-start w-full">
        {badges()}
        <p className="text-xs md:text-sm leading-tight text-ellipsis line-clamp-2">{quest.description}</p>
      </div>
    </a>
  );
};

export default QuestCard;
