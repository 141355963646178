import { resolveRoute } from "vike/routing";

export const route = (pageContext) => {
  const currentUserRoute = resolveRoute("/reading-challenge", pageContext.urlPathname);
  if (currentUserRoute.match)
    return {
      ...currentUserRoute,
      routeParams: {
        year:
          new URLSearchParams(pageContext.urlOriginal.split("?")[1]).get("year") || new Date().getFullYear().toString(),
      },
    };

  const otherUserRoute = resolveRoute("/users/@username/reading-challenge", pageContext.urlPathname);
  if (otherUserRoute.match)
    return {
      ...otherUserRoute,
      routeParams: {
        username: otherUserRoute.routeParams.username,
        year:
          new URLSearchParams(pageContext.urlOriginal.split("?")[1]).get("year") || new Date().getFullYear().toString(),
      },
    };

  return false;
};
