import { resolveRoute } from "vike/routing";

export const route = (pageContext) => {
  const pastChallengesRoute = resolveRoute("/reading-challenge/past", pageContext.urlPathname);
  if (pastChallengesRoute.match) return { ...pastChallengesRoute, precedence: 20 };

  const otherUserRoute = resolveRoute("/users/@username/reading-challenge/past", pageContext.urlPathname);
  if (otherUserRoute.match) return { ...otherUserRoute, precedence: 10 };

  return false;
};
