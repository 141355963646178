import React, { useState } from "react";
import { useSuspenseQuery, useQueryClient } from "@tanstack/react-query";
import { withFallback } from "vike-react-query";
import clsx from "clsx";
import { Rating } from "@mui/material";
import { clientOnly } from "vike-react/clientOnly";
const StarRoundedIcon = clientOnly(() => import("@mui/icons-material/StarRounded"));
import Modal from "./Modal";
import api from "#utils/api";
import { BOOK_STATUS_BG_COLOR, BOOK_STATUS_BORDER_COLOR, BOOK_STATUS_ICON_MAP } from "#utils/constants";

const BookSocialActivityModal = ({ uuid, view: initialView }) => {
  const queryClient = useQueryClient();
  const { book: currentBook } = queryClient.getQueryData(["book", uuid]);
  const [view, setView] = useState(initialView);

  const textMap = {
    tbr: "want to read",
    current: "are reading",
    finished: "finished",
    dnf: "DNFd",
    paused: "paused",
  };

  return (
    <Modal isOpen>
      <div className="md:w-[425px] flex flex-col gap-3">
        <b className="line-clamp-2 md:line-clamp-1">
          People you follow who {textMap[view]} {currentBook?.title}
        </b>
        <div className="flex flex-row gap-1.5 md:gap-3">
          {["tbr", "current", "finished", "dnf", "paused"].map((status) => (
            <button
              key={status}
              className={clsx(
                "flex items-center justify-center rounded-boxy cursor-pointer grow h-[45px] border-2",
                BOOK_STATUS_BORDER_COLOR[status],
                view === status && BOOK_STATUS_BG_COLOR[status]
              )}
              onClick={() => setView(status)}
            >
              <img src={BOOK_STATUS_ICON_MAP[status]} alt={status} className="w-[20px] h-[20px] object-contain" />
            </button>
          ))}
        </div>
        <Activity uuid={uuid} view={view} />
      </div>
    </Modal>
  );
};

const Activity = withFallback(({ uuid, view }) => {
  const { data: activity } = useSuspenseQuery({
    queryKey: ["socialActivity", uuid, view],
    staleTime: 60 * 2 * 100, // 2 minutes
    queryFn: () => api.get(`/books/${uuid}/social_activity?status=${view}`),
  });

  return (
    <div className="flex flex-col gap-3 mt-2">
      {activity.length === 0 ? (
        <div className="text-center">No activity yet!</div>
      ) : (
        activity?.map((item, i) => (
          <div key={i} className="flex flex-row gap-2 items-center">
            <div className="relative h-[30px] w-[30px]">
              <img
                src={item.user_image_url}
                alt={`${item.username}'s avatar`}
                className="rounded-full border border-offBlack absolute"
              />
            </div>
            <a href={`/users/${item.username}`}>{item.username}</a>
            {item.rating && (
              <>
                {" "}
                rated{" "}
                <Rating
                  value={Number(item.rating ?? 0)}
                  precision={0.1}
                  size="small"
                  readOnly
                  icon={<StarRoundedIcon className="stroke-offBlack text-yellow" fontSize="inherit" />}
                  emptyIcon={<StarRoundedIcon className="stroke-offBlack text-white" fontSize="inherit" />}
                />
              </>
            )}
          </div>
        ))
      )}
    </div>
  );
});

export default BookSocialActivityModal;
